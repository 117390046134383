import { useState, useEffect } from "react";
import "./CSS/teledrive.css";
import { BeatLoader } from 'react-spinners';
import teledrivelogo from './Image/teledrivelogo.png';
import telebirr from './Image/telebirr.png';
import ethiotelecomlogo from './Image/Ethio telecom logo.png'
import teledriveHome from './Image/teledriveHome.png';
import right from './Image/Right.png';
import axios from "axios";
import { AiOutlineLoading } from 'react-icons/ai'
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const TeledriveMainPage = () => {

  const Navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer zMC320eRyTIKLDAxb"
    },
  })
  const [productList, setProductList] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [addPhoneNumber, setAddPhoneNumber] = useState(true);
  const [subscribeButton, setSubscribeButton] = useState(true);
  const [subscribtionPage, setSubscribtionPage] = useState(false);
  const [customerNumber, setCustomerNumber] = useState();
  const [customerInfo, setCustomerInfo] = useState(false);
  const [subProductId, setSubProductId] = useState();
  const [selectedMandate, setSelectedMandate] = useState(null);
  const [rawRequestData, setRawRequestData] = useState(null);
  const [userSubscriptionPage, setUserSubscriptionPage] = useState(false);
  const [userSubscriptionDetail, setUserSubscriptionDetailPage] = useState(false)
  const [productIntroPage, setProductIntroPage] = useState(false);
  const [subPriductInfo, setSubPriductInfo] = useState(false);
  const [subPaymentAmount, setSubPaymentAmount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [subscriptionMessagePage, setSubscriptionMessagePage] = useState(false);
  const [merchantContractNumber, setMerchantContractNumber] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(['Subscription']);
  const [suppAppToken, setSuppAppToken] = useState()
  const [tryAgain, setTryAgain] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [data, setData] = useState({
    mandateTemplateId: "",
    executeTime: "2023-10-08",
    price: "0.00",
    merch_app_id: "10000000016",
    short_code: "513330",
  });

  const CustomToast = ({ message, backgroundColor }) => {
    return (
      <div className='toast-error'>
        {message}
      </div>
    );
  };

  const requestAuthData = (token) => {
    setIsLoading(true)
    if (token) {
      axiosInstance.post("/api/teledrivetoken", {
        authToken: token,
      }, {
        timeout: 20000
      }).then((res) => {
        const subscriptionUserInfo = res.data

        if (subscriptionUserInfo.subscriptionInfo && subscriptionUserInfo.subscriptionInfo.status === "success" &&
          subscriptionUserInfo.subscriptionInfo.data.state === "stActive") {
          if (subscriptionUserInfo.subscriptionInfo.Channel == "telebirr") {
            Navigate("/h5/SubscriptionInfo", { state: { subscriptionUserInfo } });
          }
          else if (subscriptionUserInfo.subscriptionInfo.Channel == "Other") {
            Navigate("/h5/TeledriveUnsubscribe", { state: { subscriptionUserInfo } });
          }
          else {
            toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
          setTryAgain(true)
          setIsLoading(false)
          /*   
       i
      console.log("mandetinfo", mandetinfo)
        setUserSubscriptionPage(true)
        setCustomerNumber(res.data.customerInfo.biz_content.identifier)
        setCustomerName(res.data.customerInfo.biz_content.nickName)
        setSubPriductInfo(mandetinfo.name)
        setSubPaymentAmount(mandetinfo.price)
        setSubProductId(subscriptionUserInfo.subscriptionInfo.data.productId)
        setMerchantContractNumber(subscriptionUserInfo.subscriptionInfo.data.contractNo)
        setIsLoading(false) */

        }
        else {
          setProductIntroPage(true)
          setCustomerInfo(false)
          setUserSubscriptionPage(false)
          setCustomerNumber(res.data.biz_content.identifier)
          setCustomerName(res.data.biz_content.nickName)
          setIsLoading(false)
        }
      }).catch((error) => {
        if (error.code === 'ECONNABORTED') {
          toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setTryAgain(true)
          setIsLoading(false)
        } else {
          toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setTryAgain(true)
          setIsLoading(false)
        }
      })
    }
    else {
      axiosInstance.post("/api/teledrivetoken", {
        authToken: "token",
      })
    }
  }
  const queryproductList = () => {
    return new Promise((resolve) => {
      axiosInstance.post("/api/productList", {
      }, {
        timeout: 20000
      }).then((res) => {
        setProductList(res.data)
        console.log("setProductList", res.data)
        resolve(res.data)
      }).catch((error) => {
        if (error.code === 'ECONNABORTED') {
          toast(<CustomToast message="Connection error, try again" backgroundColor="eb6c63" />, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,

          })
          setTryAgain(true)
          setIsLoading(false)

        } else {
          toast(<CustomToast message="Connection error, try again" backgroundColor="eb6c63" />, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setTryAgain(true)
          setIsLoading(false)
        }
      })
    })
  }

  const TrayAgain = () => {
    queryproductList()
    autoLogin()
    setTryAgain(false)
  }
  useEffect(() => {

    queryproductList()
    autoLogin()

  }, [])

  const autoLogin = () => {
    try {
      const MerchantAppID = process.env.REACT_APP_MERCHANT_APP_ID;
      window.handleinitDataCallback = (token) => {
        requestAuthData(token);
        setSuppAppToken(token)
      };
      let obj = JSON.stringify({
        functionName: "js_fun_h5GetAccessToken",
        params: {
          appid: MerchantAppID,
          functionCallBackName: "handleinitDataCallback",
        },
      });
      window.consumerapp.evaluate(obj);

    } catch { }
  }
  const handleSubmitPhone = () => {
    setCustomerInfo(true)
    setProductIntroPage(false)
    setUserSubscriptionPage(false)
    setSubscribeButton(false)
    setSubscribtionPage(true)
  }
  const handleSubmitPhone25 = () => {
    setCustomerInfo(true)
    setProductIntroPage(false)
    setUserSubscriptionPage(false)
    setSubscribeButton(false)
    setSubscribtionPage(true)
    setSelectedMandate("1");
  }
  const handleSubmitPhone100 = () => {
    setCustomerInfo(true)
    setProductIntroPage(false)
    setUserSubscriptionPage(false)
    setSubscribeButton(false)
    setSubscribtionPage(true)
    setSelectedMandate("2");
  }
  const handleSubmitPhoneUnlimited = () => {
    setCustomerInfo(true)
    setProductIntroPage(false)
    setUserSubscriptionPage(false)
    setSubscribeButton(false)
    setSubscribtionPage(true)
    setSelectedMandate("3");
  }


  const handleMandateChange = (event) => {
    setSelectedMandate(event.target.value);
  };
  const handleSubmitSubscription = () => {
    if (selectedMandate) {
      setIsLoadingButton(true)
      const mandetTemplateNumber = productList.find((row) => row.id === selectedMandate).mandetTemplate;
      console.log("Selected Mandate: ", selectedMandate, mandetTemplateNumber);
      doSubmit()
      setTimeout(() => {
        setIsLoadingButton(false);
      }, 20000)
    }
    else {
      console.log("Please Select Offer")
    }
  }
  window.nativeCallback = function (result) {
    setIsLoading(true)
    setSubscribtionPage(false)
    console.log("native", result)

    const regResult = JSON.parse(result)
    if (regResult.result = "success") {
      const mandateInfoCheck = {
        CustomerNumber: sessionStorage.getItem("customerNumber"),
        mandate_contract_id: regResult.mandateContractId,
        merch_contract_no: regResult.mctContractNo,
      }
      console.log("teledrive mandateInfoCheck", mandateInfoCheck)
      axiosInstance.post("/api/Check_Subscription", {
        mandateInfoCheck
      }).then((res) => {
        const subscriptionUserInfo = res.data
        console.log("teledrive subscriptionUserInfo", subscriptionUserInfo)
        if (subscriptionUserInfo.result = "success") {
          setIsLoading(false)
          Navigate("/h5/SetPassword", { state: { customerNumber } });
        }
        else {
          setIsLoading(false)
          setSubscriptionMessage("Error")
          setUserSubscriptionDetailPage(false)
          setUserSubscriptionPage(false)
          setSubscriptionMessagePage(true)
          setSubscribtionPage(false)
          setSuccessMessage("False")

        }
      })
    }
    else {
      setSubscriptionMessage("Error")
      setUserSubscriptionDetailPage(false)
      setUserSubscriptionPage(false)
      setSubscriptionMessagePage(true)
      setSubscribtionPage(false)
      setSuccessMessage("False")
    }


  }
  const doSubmit = async () => {
    const mandetTemplateNumber = productList.find((row) => row.id === selectedMandate).mandetTemplate;
    const mandetPrice = productList.find((row) => row.id === selectedMandate).price;
    const mandateName = productList.find((row) => row.id === selectedMandate).name;
    const teledriveProductId = productList.find((row) => row.id === selectedMandate).teledriveProductId;
    // console.log("Selected Mandate: ", selectedMandate, mandetTemplateNumber);
    try {
      axiosInstance.post("/api/Subscription", {
        title: "Subscription Mandet",
        amount: mandetPrice,
        mandateTemplateId: mandetTemplateNumber,
        mandateName: mandateName,
        teledriveProductId: teledriveProductId,
        CustomerNumber: customerNumber
      }).then(res => {
        // setRawRequestData(res.data.mctContractNo)
        const commonParams = {
          mctContractNo: res.data.mctContractNo,
          tradeType: "InApp",
          mctShortCode: data.short_code,
          mandateTemplateId: mandetTemplateNumber,
          thirdAppId: data.merch_app_id
        };

        //  Cookies.set("mctContractNo", res.data.mctContractNo, { expires: 7 });
        var encodeRowReuqest = encodeURIComponent(res.data.rawRequest);
        //let executeUrl = `merchant://${data.merch_app_id}?mctContractNo=${commonParams.mctContractNo}&tradeType=${commonParams.tradeType}&mctShortCode=${commonParams.mctShortCode}&mandateTemplateId=${commonParams.mandateTemplateId}&thirdAppId=${commonParams.thirdAppId}`;
        let executeUrl = res.data.executeUrl
        console.log("executeUrl1", executeUrl)
        const obj = JSON.stringify({
          functionName: "js_fun_execute",
          params: {
            execute: executeUrl,
            functionCallBackName: "startPayCallback",
            functionNativeCallbackName: "nativeCallback"
          },
        });
        if (window.consumerapp === undefined || window.consumerapp === null) {
          alert("This is not opened in app!");
          return { error: "This is not opened in app", data: null };
        }
        // console.log(executeUrl)
        // 
        window.consumerapp.evaluate(obj);
        setCookie('subscriptionData', { customerNumber: customerNumber, mctContractNo: commonParams.mctContractNo }, { path: '/' });
        console.log("commonParams", commonParams)
        console.log("mctContractNumber", res.data.mctContractNo)
        sessionStorage.clear()
        sessionStorage.setItem("customerNumber", customerNumber);
        sessionStorage.setItem("mctContractNo", commonParams.mctContractNo);
        subStatusResponse(commonParams.mctContractNo)
        //setRawRequestData(res.data.mctContractNo, "ShortCode", data.short_cod)

      });
    } catch (error) {
      console.log(error)
    }
  };

  const handleSubmitBack = () => {
    setAddPhoneNumber(true)
    setSubscribeButton(true)
    setProductIntroPage(true)
    setCustomerInfo(false)
    setUserSubscriptionPage(false)
    setSubscribeButton(true)
    setSubscribtionPage(false)
  }
  async function subStatusResponse(mctContractNo) {
    setIsLoading(true);
    setSubscribtionPage(false);
    try {
      const response = await axiosInstance.post("/api/CheckSubscription", {
        mctContractNo: mctContractNo,
        phoneNumber: customerNumber
      });
      setIsLoading(false);
      const status = response.data.status;
      const message = response.data.message;
      console.log("response", status)
      if (status == "Success") {
        Navigate("/h5/SetPassword", { state: { customerNumber } });
      }
      else if (status == "Failed") {
        setSubscriptionMessage(message)
        setUserSubscriptionDetailPage(false)
        setUserSubscriptionPage(false)
        setSubscriptionMessagePage(true)
        setSubscribtionPage(false)
        setSuccessMessage("False")
      }
      else {
        const CheckSub = {
          customerNumber: customerNumber,
          mctContractNo: mctContractNo
        }
        Navigate("/h5/CheckSubscription", { state: { CheckSub } });


      }
    } catch (error) {
    } finally {
      // Re-enable subscribe button after processing
    }
  }

  const handleSubmitUnsunscription = () => {
  }
  const handlViewSubscription = () => {
    setUserSubscriptionDetailPage(true)
    setUserSubscriptionPage(false)
  }
  const handleUnsubscribe = () => {
    setIsLoading(true)
    setUserSubscriptionPage(false)
    try {
      axiosInstance.post("/api/SubscriptionCancel", {
        CustomerNumber: customerNumber,
        productId: subProductId,
        mctContractNo: merchantContractNumber,
        authToken: suppAppToken
      }).then(res => {
        setIsLoading(false)
        if (res.data.status == "success") {

          setSubscriptionMessage("You have successfull unsubscribe the service")
          setUserSubscriptionDetailPage(false)
          setUserSubscriptionPage(false)
          setSubscribtionPage(false)
          setSubscriptionMessagePage(true)
          setSuccessMessage("True")
          console.log("CancelMandate", res.data)
        }
        else {
          setSubscriptionMessage("Error")
          setUserSubscriptionDetailPage(false)
          setUserSubscriptionPage(false)
          setSubscriptionMessagePage(true)
          setSubscribtionPage(false)
          setSuccessMessage("False")
          console.log("CancelMandate", res.data)
        }
      });
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  const handleOkUnsubscribe = () => {
    setUserSubscriptionDetailPage(false)
    setUserSubscriptionPage(false)
    setSubscriptionMessagePage(false)
    setProductIntroPage(true)
    setCustomerInfo(false)
  }



  return (
    <div className="teledrivecontainer">
      <ToastContainer />
      <div className="mainpage">
        <div className="header-container">
          <div className="header-div1">
            <img width={6} height={5}
              className="ethio-icon"
              alt=""
              src={ethiotelecomlogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={4}
              className="teledrive-icon"
              alt=""
              src={teledrivelogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={6}
              className="telebirr-icon"
              alt=""
              src={telebirr}
            />
          </div>
          {/* <div><button onClick={test}>Test</button></div> */}
        </div>

        {customerNumber && (
          <>
          {customerInfo &&
            (

           
          <div className="UserInfo">
            <div className="customerNumber">
              {customerNumber}
            </div>
            <div className="customerName">
              {customerName}
            </div>
          </div>
           )

          }
          </>
        )}
        {userSubscriptionPage && (
          <>
            <div style={{ display: "flex", width: "90%", fontSize: "14px", alignContent: "flex-start", paddingTop: "20px" }}>Subscription</div>
            <div style={{ width: "95%" }} className="subMainPage" onClick={handlViewSubscription}>
              <div className="subscriptioninfo">
                <tabl>
                  <tr><td className="subscriptioninfotitle">{"Subscribed Product: "}</td><td className="subscriptioninfocontent">{subPriductInfo}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Monthly Paymnet: "}</td><td className="subscriptioninfocontent">{subPaymentAmount} {" Birr"}</td></tr>
                </tabl>
              </div>
            </div>
          </>
        )}
        {userSubscriptionDetail && (
          <>
            <div style={{ display: "flex", width: "90%", fontSize: "14px", alignContent: "flex-start", paddingTop: "20px" }}>Subscription Detial</div>
            <div style={{ width: "95%" }} className="subDetailPage">
              <div className="subscriptionDetailinfo">
                <tabl>
                  <tr><td className="subscriptioninfotitle">{"Subscribed Product: "}</td><td className="subscriptioninfocontent">{subPriductInfo}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Monthly Paymnet: "}</td><td className="subscriptioninfocontent">{subPaymentAmount} {" Birr"}</td></tr>
                </tabl>
              </div>
              <div className="btn_subscribe"><button className="btn-conatiner" onClick={handleUnsubscribe}>
                <div className="btn-div">
                  <div className="next">Unsubscribe</div>
                </div>
              </button>
              </div>
            </div>
          </>
        )
        }
        {productIntroPage && (
          <div className="productIntroPage">
            <div className="phone-number-container">
              <div className="welecom-container">
                <b className="welcome-to-teledrive-container">
                  <span className="welcome">Welcome to</span>
                  <span className="span">{` `}</span>
                  <span className="teledrive">teledrive</span>
                </b>
              </div>
              <div className="HomeIntro">
                <p>Automatic backup of your  photos in high resolution, videos, music and docs to keep it safe and private. Don't worry anymore about losing your memories. Your content is accessible quickly and everywhere, from your smartphone, tablet or computer</p>
                {/* <p>One Month free trial for new users</p> */}
              </div>
            </div>
            <div className="bodyImage">
              {/* <img src={teledriveHome} alt="Image"  height={400}></img> */}
              <div className="teledrive-pricing">
                <div className="free-trial">
                  <h2>Free trial For One Month</h2>
                  <button className="start-trial-button" onClick={handleSubmitPhone}>Start Free trial</button>
                </div>

                <div className="plans">
                  <div className="plan1" onClick={handleSubmitPhone25}>
                    <div className="selected-circle">
                      <img width={12} height={10}
                        alt="Select"
                        src={right}
                      />
                    </div>
                    <div className='plan-type'>
                      <h3>Monthly</h3>
                      <h2>25 GB</h2>
                    </div>
                    {/* <button className="subscribe-button">Subscribe</button> */}
                    <div className='plan-sunscribe1'>Subscribe <br />30 ETB/Month</div>

                  </div>

                  <div className="plan2" onClick={handleSubmitPhone100}>
                    <div className='plan-type'>
                      <h3>Monthly</h3>
                      <h2>100 GB</h2>
                    </div>
                    <div className='plan-sunscribe2'>Subscribe <br />65 ETB/Month</div>
                  </div>
                </div>

                <div className="most-popular">
                  <div className='most-popular-title'>
                    <div className='most-popular-title1'>MOST POPULAR</div>
                  </div>
                  <div className="premium-plan">
                    <h3 style={{ marginTop: "10px", fontSize:"22px" }}>Premium</h3>
                    <h2 style={{  fontSize:"22px" }}>Unlimited Storage</h2>
                    <button className="subscribe-button" onClick={handleSubmitPhoneUnlimited}>Subscribe 300 ETB/Month</button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="btn_subscribe"><button className="btn-conatiner" onClick={handleSubmitPhone} >
              <div className="btn-div">
                <div className="next1">Next</div>
              </div>
            </button>
            </div> */}
          </div>
        )}
        {subscribtionPage &&
          (
            <div className="phone_number_div_submit1">
              <div className="phone_number_div_submit">
                <div className="phone-number">Select Monthly Subscription</div>
                <div id="mandateList" className="radio-container">

                  {productList.map((mandate) => (
                    <label key={mandate.id}>
                      <input
                        type="radio"
                        name="radioGroup"
                        value={mandate.id}
                        checked={selectedMandate === mandate.id}
                        onChange={handleMandateChange}
                      />
                      {mandate.name}
                    </label>
                  ))}
                </div>
                <div className="btn_sbscription">
                  <button className="btn-conatiner" onClick={handleSubmitSubscription} disabled={isLoadingButton}>
                    <div className="btn-div">
                      <div className="next">Subscribe</div>
                    </div>
                  </button>
                  <button className="btn-conatiner" onClick={handleSubmitBack}>
                    <div className="btn-div">
                      <div className="next">Back</div>
                    </div>
                  </button>
                </div>
              </div>

            </div>
          )}
        {subscriptionMessagePage &&
          (
            <div className="Message_page_main">
              <div className={successMessage == "True" ? 'Message_page_success' : 'Message_page_failed'}>

                <label>{subscriptionMessage}</label>
              </div>
              <div className="btn_sbscription">
                <button className="btn-conatiner" onClick={handleOkUnsubscribe}>
                  <div className="btn-div">
                    <div className="next">Ok</div>
                  </div>
                </button>
              </div>
            </div>
          )
        }
        {isLoading &&
          <div className="popup">
            <div className="popup-wait">
              <div className="popup-text">Please wait a moment</div>
              <div>
                <BeatLoader animation="border" size={30} color={"#53cd0d"} />
              </div>
            </div>
          </div>
        }
        {tryAgain &&
          <div className="popup">
            <div className="popup-wait">
              <div className="popup-text_try">Connection error, Please try again</div>
              <div>
                <button className="btn_try" onClick={TrayAgain}>Try again</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div >
  );
};
export default TeledriveMainPage;
